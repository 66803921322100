import { BusinessRuleHistory, syncTranslations } from '@driscollsinc/ggs-business-rules-module-ui';
import { withOktaAuth } from '@okta/okta-react';
import { setPageTitleAction } from 'actions/actions';
import { setSettlementWorkbenchData } from 'actions/settlementWorkbenchAction';
import DrcWrapper from 'components/DrcWrapper';
import Stepper from 'components/Stepper';
import { ROUTES, getRoute } from 'constants/routes';
import { formulaColumns, historyTableTabs, itemAssignmentColumns, ranchAssignmentColumns, ruleDetailsColumns } from 'data/BusinessRuleHistoryData';
import { DrcTranslate } from 'drc/driscolls-react-components';
import DuDateUtilities from 'drc/driscolls-react-components/Utilities/DuDateUtilities';
import useBusinessRules from 'hooks/v2/useBusinessRules';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { withRouter } from 'routes/withRouter';
import { getLanguage } from 'utils/helper';

const HISTORY_ENTITY_RULE_DETAILS = 'RuleDetails';
const HISTORY_ENTITY_FORMULAS = 'RuleFormulas';
const HISTORY_ENTITY_RANCH_ASSIGNMENTS = 'RanchAssignments';
const HISTORY_ENTITY_ITEM_ASSIGNMENTS = 'ItemAssignments';

function BusinessRulesHistoryView(props) {
    const navigate = useNavigate();
    const language = localStorage.getItem('GGS_UserLang');
    const { ruleId } = useParams();
    const itemsPerPage = 100;
    const [tabs, setTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedEntity, setSelectedEntity] = useState(HISTORY_ENTITY_RULE_DETAILS);
    const [pageNo, setPageNo] = useState(1);
    const [data, setData] = useState(null);
    const [totalItemsCount, setTotalItemsCount] = useState(1);
    const [serachKeyword, setSerachKeyword] = useState('');
    const [columns, setColumns] = useState(ruleDetailsColumns);

    const {
        typesLoading: tabsLoading,
        getBusinessRulesHistory,
        rulesLoading,
        businessRulesHistory,
        responseTime,
        businessRulesTotalCount,
        rulesError,
        businessRulesHistoryTotalCount
    } = useBusinessRules(props.oktaAuth);

    useEffect(() => {
        syncTranslations(getLanguage(language));
    }, [language]);

 

    useEffect(() => {
        if (!tabsLoading && ruleId) {
            getBusinessRulesHistory(ruleId, itemsPerPage, pageNo, selectedEntity, null, 'DESC', serachKeyword);
        }
    }, [pageNo]);

    useEffect(() => {
        if (!tabsLoading && ruleId) {
            getBusinessRulesHistory(ruleId, itemsPerPage, pageNo, selectedEntity, null, 'DESC', serachKeyword);
        }
    }, [selectedEntity]);

    useEffect(() => {
        if (!tabsLoading && ruleId) {
            getBusinessRulesHistory(ruleId, itemsPerPage, pageNo, selectedEntity, null, 'DESC', serachKeyword);
        }
    }, [rulesError]);

    const formatData = (list: any[]) => {
        return (list || []).map((item) => {
            return {
                ...item,
                CreatedDateTime: DuDateUtilities.FormatDateTimeFromIso(item.CreatedDateTime),
                ModifiedDateTime: DuDateUtilities.FormatDateTimeFromIso(item.ModifiedDateTime)
            };
        });
    };

    useEffect(() => {
        if (businessRulesHistory) {
            setTabs(historyTableTabs);
            setData(formatData(businessRulesHistory));
            setTotalItemsCount(businessRulesTotalCount);
        }
    }, [businessRulesHistory]);

    const onTabChange = (index: number, value?: string) => {
        if (value) {
            setPageNo(1);
            setSelectedTab(index);
            if (index === 0) {
                setSelectedEntity(HISTORY_ENTITY_RULE_DETAILS);
                setColumns(ruleDetailsColumns);
            } else if (index === 1) {
                setColumns(formulaColumns);
                setSelectedEntity(HISTORY_ENTITY_FORMULAS);
            } else if (index === 2) {
                setColumns(ranchAssignmentColumns);
                setSelectedEntity(HISTORY_ENTITY_RANCH_ASSIGNMENTS);
            } else if (index === 3) {
                setColumns(itemAssignmentColumns);
                setSelectedEntity(HISTORY_ENTITY_ITEM_ASSIGNMENTS);
            }
        }
    };

    const onPageChange = (pageNo: number) => {
        if (pageNo) {
            setPageNo(pageNo);
        }
    };

    const onSearch = (keyword) => {
        setSerachKeyword(keyword);
    };

    const goBack = () => {
        navigate(getRoute(ROUTES.BUSINESS_RULES_EDIT, ruleId));
    };

    return (
        <DrcWrapper>
            <Stepper showBackButton={true} goBack={goBack} page={'Business Rules / History'} />
            <div style={{ padding: '0 20px', height: 'calc(100% - 100px)' }}>
                {data && (
                    <BusinessRuleHistory
                        errorMessage={rulesError}
                        columns={columns}
                        currentPage={pageNo}
                        tabs={tabs}
                        selectedTab={selectedTab}
                        displayTabsCount={5}
                        isLoading={rulesLoading}
                        isTableLoading={rulesLoading}
                        data={data}
                        totalItems={isNaN(businessRulesHistoryTotalCount) || businessRulesHistoryTotalCount <= 0 ? 1 : businessRulesHistoryTotalCount}
                        itemsPerPage={itemsPerPage}
                        onTabChange={onTabChange}
                        onPageChange={onPageChange}
                        noRowMessage={'No data found'}
                        onSearch={onSearch}
                    />
                )}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', marginTop: '16px' }}>
                    <DrcTranslate>{'Results loaded in'}</DrcTranslate> {`${responseTime.toFixed(1)} s`}
                </div>
            </div>
        </DrcWrapper>
    );
}

const mapStateToProps = ({ settlementWorkbenchReducer, rootReducer }) => ({
    total: settlementWorkbenchReducer.totalRecords,
    pageTitle: rootReducer.pageTitle,
    records: settlementWorkbenchReducer.records
});

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setSettlementWorkbenchData: (data) => dispatch(setSettlementWorkbenchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(BusinessRulesHistoryView)));
