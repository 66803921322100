// @ts-nocheck
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { DuDateUtilities } from '@driscollsinc/driscolls-react-utilities';
import { CreateBusinessRules, ExceptionDialog, syncTranslations } from '@driscollsinc/ggs-business-rules-module-ui';
import { ButtonSize, ButtonVariant, DriscollsButton } from '@driscollsinc/style-guide-web2.0';
import { withOktaAuth } from '@okta/okta-react';
import { hideLoadingScreenAction, setPageTitleAction, showLoadingScreenAction, showToast } from 'actions/actions';
import { setSettlementWorkbenchData } from 'actions/settlementWorkbenchAction';
import DrcWrapper from 'components/DrcWrapper';
import Stepper from 'components/Stepper';
import { BusinessRuleActions } from 'constants/BusinessRuleActions';
import { ROUTES, getRoute } from 'constants/routes';
import { convertDateToIso } from 'data/constants';
import { EventConstants } from 'data/EventConstants';
import { BUSINESSRULE_CARD_FIELDS } from 'data/BusinessRules';
import { DrcTranslate } from 'drc/driscolls-react-components';
import useAddRowFields from 'hooks/v2/useAddRowFields';
import useBusinessRules from 'hooks/v2/useBusinessRules';
import useGAAnalytics from 'hooks/v2/useGAAnalytics';
import useGetFilterData from 'hooks/v2/useGetFilterData';
import { NAMESPACE } from 'i18n';
import useLang from 'hooks/v2/useLang';
import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { withRouter } from 'routes/withRouter';
import { getLanguage, isValidRuleID, userBusinessUnit } from 'utils/helper';
import { Store } from 'data/store';
import ApplicationContext from 'context/ApplicationContext';
import { urls } from 'constants/urls';
import _ from 'lodash';

const SAVE_ACTION_TYPE: string = 'save';
const CREATE_ACTION_TYPE: string = 'create';
const MODIFY_ACTION_TYPE: string = 'modify';
const SUBMIT_ACTION_TYPE: string = 'submit';
let itemSelected;
let ranchSelected;
function CreateOrEditBusinessRule(props) {
    const { pathname } = useLocation();
    const { logEvent, logError } = useGAAnalytics();
    const { getFormattedProducingArea, getFormattedGrowers, getFormattedItems, getFormattedRanches, getFormatedBusinessRuleCardFields } =
        useGetFilterData();
    const { getTextTranslated } = useLang();
    const language = localStorage.getItem('GGS_UserLang');
    const [pricingPoolLoaded, setPricingPoolLoaded] = useState<boolean>(false);
    const [openExceptionDialog, setOpenExceptionDialog] = useState<boolean>(false);
    let { ruleId, ruleType } = useParams();
    const [ruleTypeId, setRuleTypeId] = useState('');
    const [pricingpoolId, setPricingpoolId] = useState(null | '');
    const navigate = useNavigate();
    const FormulaSeq = '1',
        FormulaType_AMOUNT = 'Amount',
        FormulaType_PERCENT = 'Percent',
        FormulaType_AMOUNT_PER_UNIT = 'AmountPerUnit',
        CurrencyCode = 'USD';
    const [loading, setLoading] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isCopyMode, setIsCopyMode] = useState<boolean>(false);
    const [selectedRanchFilter, setSelectedRanchFilter] = useState<[]>([]);
    const [selectedItemFilter, setSelectedItemFilter] = useState<[]>([]);
    const [selectedRuleCreationFlow, setSelectedRuleCreationFlow] = useState<string>(null);
    const [loadAssignmentOnFilterChange, setLoadAssignmentOnFilterChange] = useState<boolean>(null);
    const [ranchFilterChanged, setRanchFilterChanged] = useState<boolean>(null);
    const [itemFilterChanged, setItemFilterChanged] = useState<boolean>(null);

    const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(true);
    const isCreateMode = matchPath(ROUTES.BUSINESS_RULES + ROUTES.BUSINESS_RULES_CREATE, pathname);

    //No need them to be render triggers

    const setItemSelected = (value) => {
        itemSelected = value;
    };

    const setRanchSelected = (value) => {
        ranchSelected = value;
    };

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (hasUnsavedChanges) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [hasUnsavedChanges]);

    useEffect(() => {
        syncTranslations(getLanguage(language));
    }, [language]);

    const [selectedRowItem, setSelectedRowItem] = useState({
        producingArea: [],
        grower: [],
        ranch: [],
        berryType: [],
        fieldType: [],
        brand: [],
        item: []
    });
    const [createRowDetails, setCreateRowDetails] = useState({
        producingAreaList: [],
        growerList: [],
        ranchList: [],
        berryTypeList: [],
        fieldTypeList: [],
        brandList: [],
        itemList: [],
        producingAreaSelectedList: [],
        growerSelectedList: [],
        ranchSelectedList: [],
        berryTypeSelectedList: [],
        fieldTypeSelectedList: [],
        brandSelectedList: [],
        itemSelectedList: [],
        showSummary: false
    });
    const [businessRuleCardFields, setBusinessRuleCardFields] = useState(BUSINESSRULE_CARD_FIELDS['default']);

    const {
        getPricingPoolList,
        getLookupData,
        getRuleDetails,
        getApprovers,
        approverList,
        ruleDetailsError,
        ruleDetailsLoading,
        ruleDetails,
        submitRule,
        ruleCreateResponse,
        ruleCreateError,
        ruleCreateLoading,
        lookupData,
        lookupDataLoading,
        responseTime,
        businessRules,
        LOOKUP_TYPE_BERRYTYPE,
        LOOKUP_TYPE_BRAND,
        LOOKUP_TYPE_FIELDTYPE,
        approverGroupId,
        submitForApprovalResponse,
        getBusinessRules
    } = useBusinessRules(props.oktaAuth);
    const pagesize = 1000,
        startpage = 1,
        ranchPagesize = 3500;
    const {
        producingArea,
        getProducingArea,
        grower,
        getGrower,
        ranch,
        getRanch,
        item,
        getItem,
        ranchLoading,
        growerLoading,
        producingAreaLoading,
        itemLoading,
        brands,
        fieldTypes,
        berryTypes,
        getAssignableList,
        getAssignableData,
        assignmentList,
        getFilteredAssignmentForSummary,
        filteredSummaryData
    } = useAddRowFields(props.oktaAuth);
    const { assignmentListForItems, setAssignmentListForItems } = useContext(ApplicationContext);

    useEffect(() => {
        if (!pricingPoolLoaded) {
            getFormattedProducingArea(producingArea, setCreateRowDetails);
        }
    }, [producingArea]);

    useEffect(() => {
        getFormattedGrowers(grower, setCreateRowDetails);
    }, [grower]);

    useEffect(() => {
        getFormattedRanches(ranch, setCreateRowDetails, createRowDetails);
    }, [ranch]);

    useEffect(() => {
        getFormattedItems(item, setCreateRowDetails, createRowDetails);
    }, [item]);

    useEffect(() => {
        if (assignmentList?.AssignableItemList) {
            setAssignmentListForItems(assignmentList);
        }
    }, [assignmentList]);

    useEffect(() => {
        const isEditMode = matchPath(ROUTES.BUSINESS_RULES + ROUTES.BUSINESS_RULES_EDIT, pathname);
        getApprovers();
        getPricingPoolList();

        if (!assignmentListForItems) {
            getAssignments();
        } else {
            getAssignableData(assignmentListForItems);
        }
    }, []);

    useEffect(() => {
        if (businessRules && businessRules.length > 0) {
            let updatedFields = {
                ...businessRuleCardFields,
                packagingPool: {
                    ...businessRuleCardFields.packagingPool,
                    options: businessRules.map((brule) => {
                        return { value: brule.RuleId, label: `${brule.RuleId}- ${brule.RuleName}` };
                    })
                }
            };
            setBusinessRuleCardFields({ ...updatedFields });
        }
    }, [businessRules]);

    useEffect(() => {
        if (ruleDetailsError) {
            props.showToast(JSON.stringify(ruleDetailsError).slice(0, 200));
        }
    }, [ruleDetailsError]);

    useEffect(() => {
        setCreateRowDetails((prev) => {
            return {
                ...prev,
                berryTypeList: berryTypes,
                fieldTypeList: fieldTypes,
                brandList: brands
            };
        });
    }, [brands, fieldTypes, berryTypes]);

    useEffect(() => {
        if (filteredSummaryData?.Items && filteredSummaryData?.Ranches) {
            setCreateRowDetails((prev) => {
                return {
                    ...prev,
                    itemSelectedList: filteredSummaryData.Items.map((item) => {
                        return { ...item, label: `${item.ItemNbr || ''} - ${item.ItemName || ''}`, value: item.ItemNbr };
                    }),
                    ranchSelectedList: filteredSummaryData.Ranches.map((item) => {
                        return { ...item, label: `${item.RanchNbr || ''} - ${item.RanchName || ''}`, value: item.RanchNbr };
                    })
                };
            });
        }
    }, [filteredSummaryData]);

    useEffect(() => {
        if ((selectedRanchFilter.length > 0 || selectedItemFilter.length > 0) && loadAssignmentOnFilterChange) {
            setItemFilterChanged(false);
            setRanchFilterChanged(false);
            getAssignments(selectedRanchFilter, selectedItemFilter, true, selectedRuleCreationFlow, pricingPoolLoaded);
        }
    }, [loadAssignmentOnFilterChange]);

    useEffect(() => {
        if (selectedRuleCreationFlow === 'selection') {
            setItemFilterChanged(false);
            setRanchFilterChanged(false);
            getAssignments(selectedRanchFilter, selectedItemFilter, true, selectedRuleCreationFlow, pricingPoolLoaded);
        }
    }, [selectedRuleCreationFlow]);

    const getAssignments = (
        RanchFilters: [],
        ItemFilters: [],
        loadItems: boolean = false,
        selctionFlow: string,
        isSettlementPool: boolean,
        savedRuleDetails: any = null
    ) => {
        const state = Store.getState();
        const payload = {
            JSONRequest: {
                Action: 'GET_ASSIGNABLE_LIST',
                BusinessUnit: userBusinessUnit(),
                LanguageCode: state?.masterReducer?.userLang?.toLowerCase(),
                ...(RanchFilters && RanchFilters.length > 0 && { RanchFilters }),
                ...(ItemFilters && ItemFilters.length > 0 && { ItemFilters })
            }
        };
        getAssignableList(payload, loadItems, itemSelected, ranchSelected, isSettlementPool, savedRuleDetails);
    };

    const updateBusinessRuleCardFields = (details) => {
        if (details && details.RuleTypeId) {
            let cardFields = getFormatedBusinessRuleCardFields(
                details,
                isEditMode,
                BUSINESSRULE_CARD_FIELDS[details.RuleTypeId] || BUSINESSRULE_CARD_FIELDS['default']
            );
            if (businessRules && businessRules.length > 0) {
                cardFields = {
                    ...cardFields,
                    comment: { ...cardFields.rejectReason, value: details.Reason },
                    packagingPool: {
                        ...cardFields.packagingPool,
                        options: businessRules.map((brule) => {
                            return { value: brule.RuleId, label: `${brule.RuleId}- ${brule.RuleName}` };
                        })
                    }
                };
            }
            setBusinessRuleCardFields({ ...cardFields });
        }
    };

    const loadFilterCriteria = () => {
        const producingAreaCodes = [...new Set(ruleDetails[0]?.RanchAssignment?.map((item) => item.ProducingAreaCode))];
        const growerNbrs = [...new Set(ruleDetails[0]?.RanchAssignment?.map((item) => item.GrowerNbr))];
        const berryTypes = [...new Set(ruleDetails[0]?.ItemAssignment?.map((item) => item.BerryType))];
        const brands = [...new Set(ruleDetails[0]?.ItemAssignment?.map((item) => item.Brand))];
        const packageTypes = [...new Set(ruleDetails[0]?.ItemAssignment?.map((item) => item.FieldType))];
        getProducingArea(
            pagesize,
            startpage,
            berryTypes?.length ? berryTypes : null,
            packageTypes?.length ? packageTypes : null,
            shouldLoadFromAssignment()
        );
        getGrower(
            pagesize,
            startpage,
            {
                BerryTypes: berryTypes.length ? berryTypes : null,
                FieldTypes: packageTypes.length ? packageTypes : null,
                ProducingAreaCodes: producingAreaCodes?.length ? producingAreaCodes : null
            },
            shouldLoadFromAssignment()
        );
        getRanch(
            ranchPagesize,
            startpage,
            {
                BerryTypes: berryTypes.length ? berryTypes : null,
                FieldTypes: packageTypes.length ? packageTypes : null,
                GrowerNbrs: growerNbrs?.length ? growerNbrs : null,
                ProducingAreaCodes: producingAreaCodes?.length ? producingAreaCodes : null
            },
            shouldLoadFromAssignment()
        );
        if (berryTypes?.length && brands?.length && packageTypes?.length) {
            getItem(
                pagesize,
                startpage,
                {
                    berryTypes: berryTypes,
                    brands: brands,
                    packageTypes: packageTypes
                },
                shouldLoadFromAssignment()
            );
        }
    };

    const formatRuleDetails = (details) => {
        let berryType;
        let fieldType;
        let brand;
        let item;
        if (details && details.RanchAssignment && details.RanchAssignment.length > 0) {
            let producingArea = details.RanchAssignment.map((item) => {
                return { value: item.ProducingAreaCode, label: `${item.ProducingAreaCode} - ${item.ProducingAreaName}` };
            });
            producingArea = [...new Map(producingArea.map((item) => [item['value'], item])).values()];
            let grower = details.RanchAssignment.map((item) => {
                return { value: item.GrowerNbr, label: `${item.GrowerNbr} - ${item.GrowerName}`, producingarea: item.ProducingAreaCode };
            });
            grower = [...new Map(grower.map((item) => [item['value'], item])).values()];
            let ranch = details.RanchAssignment.map((item) => {
                return { value: item.RanchNbr, label: `${item.RanchNbr} - ${item.RanchName}` };
            });
            ranch = [...new Map(ranch.map((item) => [item['value'], item])).values()];

            if (details.ItemAssignment && details.ItemAssignment.length > 0) {
                berryType = details.ItemAssignment.map((item) => {
                    return { value: item.BerryType, label: item.BerryType };
                });
                berryType = [...new Map(berryType.map((item) => [item['value'], item])).values()];
                fieldType = details.ItemAssignment.map((item) => {
                    return { value: item.FieldType, label: item.FieldType };
                });
                fieldType = [...new Map(fieldType.map((item) => [item['value'], item])).values()];
                brand = details.ItemAssignment.map((item) => {
                    return { value: item.Brand, label: item.Brand };
                });
                brand = [...new Map(brand.map((item) => [item['value'], item])).values()];
                item = details.ItemAssignment.map((item) => {
                    return { value: item.ItemNbr, label: `${item.ItemNbr} - ${item.ItemName}` };
                });
                item = [...new Map(item.map((item) => [item['value'], item])).values()];
            }
            //Setting the ranch and item filters
            setSelectedItemFilter(
                (item || []).map((item) => {
                    return item.value;
                })
            );
            setSelectedRanchFilter(
                (ranch || []).map((item) => {
                    return item.value;
                })
            );

            // getAssignments(
            //     (ranch || []).map((item) => {
            //         return item.value;
            //     }),
            //     (item || []).map((item) => {
            //         return item.value;
            //     }),
            //     true,
            //     selectedRuleCreationFlow,
            //     shouldLoadFromAssignment()
            // );

            setSelectedRowItem({
                producingArea,
                grower,
                ranch,
                berryType,
                fieldType,
                brand,
                item
            });

            setCreateRowDetails((prev) => {
                return {
                    ...prev,
                    producingAreaSelectedList: producingArea,
                    growerSelectedList: grower,
                    ranchSelectedList: ranch,
                    berryTypeSelectedList: berryType,
                    fieldTypeSelectedList: fieldType,
                    brandSelectedList: brand,
                    itemSelectedList: item
                };
            });
        }
    };

    useEffect(() => {
        if (ruleDetails && ruleDetails.length > 0) {
            if (!pricingPoolLoaded) {
                updateBusinessRuleCardFields(ruleDetails[0]);
                formatRuleDetails(ruleDetails[0]);
                setRuleTypeId(ruleDetails[0].RuleTypeId);
                //Rule is settlement pool and has a pricing pool associated with it
                //have to load the those details
                //Same data will be acting as item and ranch master data for the current settlement pool edit
                if (ruleDetails[0].PricingPoolId) {
                    setPricingpoolId(ruleDetails[0].PricingPoolId);
                    setPricingPoolLoaded(true);
                    getRuleDetails(ruleDetails[0].PricingPoolId);
                }
            } else {
                const details = ruleDetails[0];
                let berryType;
                let fieldType;
                let brand;
                let item;
                let producingArea = (ruleDetails[0].RanchAssignment || []).map((item) => {
                    return { value: item.ProducingAreaCode, label: `${item.ProducingAreaCode} - ${item.ProducingAreaName}` };
                });
                producingArea = [...new Map(producingArea.map((item) => [item['value'], item])).values()];
                let grower = (details.RanchAssignment || []).map((item) => {
                    return { value: item.GrowerNbr, label: `${item.GrowerNbr} - ${item.GrowerName}` };
                });
                grower = [...new Map(grower.map((item) => [item['value'], item])).values()];
                let ranch = (details.RanchAssignment || []).map((item) => {
                    return { value: item.RanchNbr, label: `${item.RanchNbr} - ${item.RanchName}` };
                });
                ranch = [...new Map(ranch.map((item) => [item['value'], item])).values()];

                if (details.ItemAssignment && details.ItemAssignment.length > 0) {
                    berryType = details.ItemAssignment.map((item) => {
                        return { value: item.BerryType, label: item.BerryType };
                    });
                    berryType = [...new Map(berryType.map((item) => [item['value'], item])).values()];
                    fieldType = details.ItemAssignment.map((item) => {
                        return { value: item.FieldType, label: item.FieldType };
                    });
                    fieldType = [...new Map(fieldType.map((item) => [item['value'], item])).values()];
                    brand = details.ItemAssignment.map((item) => {
                        return { value: item.Brand, label: item.Brand };
                    });
                    brand = [...new Map(brand.map((item) => [item['value'], item])).values()];
                    item = details.ItemAssignment.map((item) => {
                        return { value: item.ItemNbr, label: `${item.ItemNbr} - ${item.ItemName}` };
                    });
                    item = [...new Map(item.map((item) => [item['value'], item])).values()];
                }
                //Setting the ranch and item filters
                setSelectedItemFilter(
                    (item || []).map((item) => {
                        return item.value;
                    })
                );
                setSelectedRanchFilter(
                    (ranch || []).map((item) => {
                        return item.value;
                    })
                );
                // getAssignments(
                //     (ranch || []).map((item) => {
                //         return item.value;
                //     }),
                //     (item || []).map((item) => {
                //         return item.value;
                //     }),
                //     true,
                //     selectedRuleCreationFlow,
                //     shouldLoadFromAssignment()
                // );

                setCreateRowDetails((prev) => {
                    return {
                        ...prev,
                        producingAreaList: producingArea,
                        growerList: grower,
                        ranchList: ranch,
                        berryTypeList: berryType,
                        fieldTypeList: fieldType,
                        brandList: brand,
                        itemList: item
                    };
                });
            }
            //Setting the start date and end date for the settlement pool as the one loaded from the set pricing pool
            setBusinessRuleCardFields((prev) => {
                return {
                    ...prev,
                    packagingPool: {
                        ...prev.packagingPool,
                        value: pricingpoolId,
                        options: businessRules.map((brule) => {
                            return { value: brule.RuleId, label: `${brule.RuleId}- ${brule.RuleName}` };
                        })
                    },
                    startDate: {
                        ...prev.startDate,
                        value: ruleDetails[0]?.StartDate ? DuDateUtilities.FormatDateFromIso(ruleDetails[0]?.StartDate) : null
                    },
                    endDate: { ...prev.endDate, value: ruleDetails[0]?.EndDate ? DuDateUtilities.FormatDateFromIso(ruleDetails[0]?.EndDate) : null }
                };
            });

            //Loading the Grower,Ranch,brand,berry type, fieldtype and items drop down items
            loadFilterCriteria();
        }
    }, [ruleDetails]);

    useEffect(() => {
        setCreateRowDetails((prev) => {
            return {
                ...prev,
                isRanchLoading: ranchLoading,
                isGrowerLoading: growerLoading
            };
        });
    }, [growerLoading, ranchLoading]);
    useEffect(() => {
        setLoading(ruleDetailsLoading);
    }, [ruleDetailsLoading]);

    useEffect(() => {
        // Reset the loading after businessRuleCardFields is upadted (on create businedd rule)
        setLoading(false);
    }, [businessRuleCardFields]);

    useEffect(() => {
        setLoading(ruleCreateLoading);
    }, [ruleCreateLoading]);

    useEffect(() => {
        if (ruleType) {
            setRuleTypeId(Number(ruleType));
        }
    }, [ruleType]);

    useEffect(() => {
        const isEditMode = matchPath(ROUTES.BUSINESS_RULES + ROUTES.BUSINESS_RULES_EDIT, pathname);
        const isCopyMode = matchPath(ROUTES.BUSINESS_RULES + ROUTES.BUSINESS_RULES_COPY, pathname);
        if (isCreateMode && ruleTypeId) {
            setIsEditMode(false);
            setLoading(true);
            let cardFields = BUSINESSRULE_CARD_FIELDS[Number(ruleTypeId)] || BUSINESSRULE_CARD_FIELDS['default'];
            const dateValueWZ: any = DuDateUtilities.ToIsoDate(new Date(), false);
            const selectedDate = dateValueWZ ? new Date(dateValueWZ) : new Date();
            setBusinessRuleCardFields({
                ...cardFields,
                startDate: { ...cardFields.startDate, value: dateValueWZ },
                ruleId: { ...cardFields.ruleId, visible: false },
                status: { ...cardFields.status, value: 'NEW', disabled: true }
            });
        } else if (isEditMode && ruleId) {
            setIsEditMode(true);
            getRuleDetails(ruleId);
        } else if (isCopyMode && ruleId) {
            setIsEditMode(false);
            setIsCopyMode(true);
            getRuleDetails(ruleId);
        }
    }, [ruleId, ruleTypeId]);

    useEffect(() => {
        if (ruleCreateError) {
            setOpenExceptionDialog(true);
        }
    }, [ruleCreateError]);

    useEffect(() => {
        if (ruleCreateResponse) {
            if (isEditMode) {
                props.showToast(ruleCreateResponse, true);
                setHasUnsavedChanges(false);
                goBack();
            } else {
                goBack();
            }
        }
    }, [ruleCreateResponse]);

    useEffect(() => {
        if (submitForApprovalResponse) {
            goBack();
        }
    }, [submitForApprovalResponse]);

    const onResetData = (rowFields) => {
        clearRowDetails();
        setRanchSelected(false);
        setItemSelected(false);
        getAssignments(selectedRanchFilter, selectedItemFilter, true, selectedRuleCreationFlow, pricingPoolLoaded);
        setItemFilterChanged(false);
        setRanchFilterChanged(false);
        if (!pricingPoolLoaded) {
            setSelectedItemFilter([]);
            setSelectedRanchFilter([]);
        }
    };

    const onRowSelectedFieldsChange = (rowFields) => {
        setCreateRowDetails((prev) => {
            return {
                ...prev,
                producingAreaSelectedList: rowFields.producingArea,
                growerSelectedList: rowFields.grower,
                ranchSelectedList: rowFields.ranch,
                berryTypeSelectedList: rowFields.berryType,
                fieldTypeSelectedList: rowFields.fieldType,
                brandSelectedList: rowFields.brand,
                itemSelectedList: rowFields.item
            };
        });
    };

    const onCommitChangeOnClose = async (type: string, selected: any) => {
        const selectedValues = selected.map((item) => item.value);
        if (type === 'ranch' && selected.length > 0 && !itemSelected) {
            setRanchSelected(true);
            setItemFilterChanged(!_.isEqual(selected, selectedItemFilter));
            setSelectedRanchFilter(selectedValues);
        } else if (type === 'item' && selected.length > 0 && !ranchSelected) {
            setItemSelected(true);
            setRanchFilterChanged(!_.isEqual(selected, selectedRanchFilter));
            setSelectedItemFilter(selectedValues);
        }
    };

    const onRowFieldChange = async (filed, data) => {
        const producingAreaCodes = data.producingArea.map((item) => item.value);
        const growerNbrs = data.grower.map((item) => item.value);
        const ranches = data.ranch.map((item) => item.value);
        const berryTypes = data.berryType.map((item) => item.value);
        const brands = data.brand.map((item) => item.value);
        const packageTypes = data.fieldType.map((item) => item.value);
        const item = data.item.map((item) => item.value);
        const some = data.grower.filter((item) => data.producingArea.find((producingArea) => producingArea.value === item.producingarea));
        logEvent(EventConstants.BUSINESS_RULE_FILTER_CHANGE, { FilterType: filed, RuleType: ruleTypeId });
        switch (filed) {
            case 'producingArea':
                if (producingAreaCodes.length) {
                    if (ranchSelected) {
                        setRanchSelected(false);
                    }
                    getGrower(
                        pagesize,
                        startpage,
                        {
                            ProducingAreaCodes: producingAreaCodes.length ? producingAreaCodes : null,
                            BerryTypes: berryTypes.length ? berryTypes : null,
                            FieldTypes: packageTypes.length ? packageTypes : null
                        },
                        shouldLoadFromAssignment()
                    );
                    setCreateRowDetails((prev) => {
                        return {
                            ...prev,
                            growerSelectedList: some,
                            producingAreaSelectedList: data.producingArea
                        };
                    });
                } else {
                    setCreateRowDetails((prev) => {
                        return {
                            ...prev,
                            growerList: [],
                            growerSelectedList: [],
                            ranchList: [],
                            ranchSelectedList: [],
                            producingAreaSelectedList: data.producingArea
                        };
                    });
                }

                break;
            case 'grower':
                if (growerNbrs.length) {
                    if (ranchSelected) {
                        setRanchSelected(false);
                    }
                    setCreateRowDetails((prev) => {
                        return {
                            ...prev,
                            growerSelectedList: data.grower
                        };
                    });
                    getRanch(
                        ranchPagesize,
                        startpage,
                        {
                            BerryTypes: berryTypes.length ? berryTypes : null,
                            FieldTypes: packageTypes.length ? packageTypes : null,
                            GrowerNbrs: growerNbrs.length ? growerNbrs : null,
                            ProducingAreaCodes: producingAreaCodes.length ? producingAreaCodes : null
                        },
                        shouldLoadFromAssignment()
                    );
                } else {
                    setCreateRowDetails((prev) => {
                        return {
                            ...prev,
                            ranchList: [],
                            ranchSelectedList: []
                        };
                    });
                }
                break;
            case 'berryType':
            case 'brand':
            case 'fieldType':
                if (berryTypes.length && brands.length && packageTypes.length) {
                    if (itemSelected) {
                        setItemSelected(false);
                    }
                    setCreateRowDetails((prev) => {
                        return {
                            ...prev,
                            berryTypeSelectedList: data.berryType,
                            brandSelectedList: data.brand,
                            fieldTypeSelectedList: data.fieldType
                        };
                    });
                    getItem(
                        pagesize,
                        startpage,
                        {
                            berryTypes: berryTypes,
                            brands: brands,
                            packageTypes: packageTypes
                        },
                        shouldLoadFromAssignment()
                    );
                } else {
                    setCreateRowDetails((prev) => {
                        return {
                            ...prev,
                            itemList: [],
                            itemSelectedList: []
                        };
                    });
                }
                break;
            case 'item':
                if (item.length > 0) {
                    setCreateRowDetails((prev) => {
                        return {
                            ...prev,
                            itemSelectedList: data.item
                        };
                    });
                }
                break;
            case 'ranch':
                if (ranch.length > 0) {
                    setCreateRowDetails((prev) => {
                        return {
                            ...prev,
                            ranchSelectedList: data.ranch
                        };
                    });
                }
                break;
        }
    };

    const onDeleteRow = () => {
        setCreateRowDetails((prev) => ({
            ...prev,
            producingAreaSelectedList: [],
            growerSelectedList: [],
            ranchSelectedList: [],
            berryTypeSelectedList: [],
            fieldTypeSelectedList: [],
            brandSelectedList: [],
            itemSelectedList: []
        }));
    };

    const onSubmit = async (type, businessRulesDetails, selectedRowDetails) => {
        logEvent(EventConstants.BUSINESS_RULE_UPDATE, { Type: type, RuleType: ruleTypeId });
        let token = await props.oktaAuth.getAccessToken();
        let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
        let loggedInUserName = DuAuthenticationUtilities.GetUserId(token);
        const ranchesList = selectedRowDetails?.ranch ? selectedRowDetails.ranch.map((item) => item.value) : [];
        const itemsList = selectedRowDetails?.item ? selectedRowDetails.item.map((item) => item.value) : [];
        const forumulaType = businessRulesDetails.rateType || businessRuleCardFields.formulaType?.value || FormulaType_AMOUNT_PER_UNIT;
        let data = {
            RuleTypeId: ruleTypeId,
            BusinessUnit: userBusinessUnit(),
            RuleType: businessRulesDetails.ruleType,
            RuleName: businessRulesDetails.description,
            Status: businessRulesDetails.status,
            StartDate: businessRulesDetails.startDate ? DuDateUtilities.ToIsoDate(new Date(businessRulesDetails.startDate)) : '',
            EndDate: businessRulesDetails.endDate ? DuDateUtilities.ToIsoDate(new Date(businessRulesDetails.endDate)) : '',
            UOM: businessRulesDetails.uom,
            PFQFlag: businessRulesDetails.pfq,
            ...(businessRulesDetails.pfq && { PricingPoolId: pricingpoolId }),
            Formula: [
                {
                    ...(type !== SUBMIT_ACTION_TYPE && !isEditMode && { CreatedBy: loggedInUser }),
                    ...(type !== SUBMIT_ACTION_TYPE && !isEditMode && { CreatedDateTime: new Date().toISOString() }),
                    ...(type !== SUBMIT_ACTION_TYPE && isEditMode && { ModifiedBy: loggedInUser }),
                    ...(type !== SUBMIT_ACTION_TYPE && isEditMode && { ModifiedDateTime: new Date().toISOString() }),
                    FormulaSeq: FormulaSeq,
                    FormulaType: forumulaType,
                    CurrencyCode: CurrencyCode,
                    FormulaValue:
                        businessRulesDetails.defaultPrice ||
                        businessRulesDetails.ruleValue ||
                        businessRulesDetails.advanceAmount ||
                        businessRulesDetails.defaultPackagingCost ||
                        businessRulesDetails.defaultRelativeScore ||
                        businessRulesDetails.sharePercent ||
                        businessRulesDetails.harvestCost
                },
                businessRulesDetails.financePercent && {
                    ...(type === SUBMIT_ACTION_TYPE && !isEditMode && { CreatedBy: loggedInUser }),
                    ...(type === SUBMIT_ACTION_TYPE && !isEditMode && { CreatedDateTime: new Date().toISOString() }),
                    ...(type !== SUBMIT_ACTION_TYPE && isEditMode && { ModifiedBy: loggedInUser }),
                    ...(type !== SUBMIT_ACTION_TYPE && isEditMode && { ModifiedDateTime: new Date().toISOString() }),
                    FormulaSeq: FormulaSeq,
                    FormulaType: FormulaType_PERCENT,
                    CurrencyCode: CurrencyCode,
                    FormulaValue: businessRulesDetails.financePercent
                }
            ].filter((item) => {
                return item && item.FormulaSeq;
            }),
            Assignment: { ranches: ranchesList, items: itemsList }
        };
        if (isEditMode) {
            data = {
                ...data,
                RuleId: ruleId
            };
        }
        // Set the saved data so won't get lost on relaod
        updateBusinessRuleCardFields({ ...data });
        if (type !== SUBMIT_ACTION_TYPE) {
            if (isEditMode) {
                submitRule({
                    Action: BusinessRuleActions.MODIFY,
                    BusinessRule: { ...data, ModifiedBy: loggedInUser, ModifiedDateTime: new Date().toISOString() }
                });
            } else {
                submitRule({
                    Action: BusinessRuleActions.CREATE,
                    BusinessRule: { ...data, CreatedBy: loggedInUser, CreatedDateTime: new Date().toISOString() }
                });
            }
        } else {
            submitRule({
                Action: BusinessRuleActions.REQUEST4APPROVAL,
                BusinessRule: {
                    ...data,
                    CreatedBy: businessRulesDetails?.createdBy?.split('@')[0],
                    ModifiedBy: loggedInUser,
                    ModifiedDateTime: new Date().toISOString()
                },
                UserName: type === SUBMIT_ACTION_TYPE ? loggedInUserName : '',
                UserEmail: type === SUBMIT_ACTION_TYPE ? loggedInUser : '',
                WebURL: type === SUBMIT_ACTION_TYPE ? ROUTES.BUSINESS_RULES + ROUTES.BUSINESS_RULES_APPROVE + `?${urls.RULE_ID}=` + ruleTypeId : '',
                GroupId: type === SUBMIT_ACTION_TYPE ? approverGroupId : ''
            });
        }
    };

    const goBack = () => {
        navigate(getRoute(ROUTES.BUSINESS_RULES_LIST, ruleType || ruleTypeId));
    };

    const onPricingPoolChange = (pricingpoolId: string) => {
        logEvent(EventConstants.BUSINESS_RULE_PRICING_POOL_SELECTION, { PricingPoolId: pricingpoolId });
        if (pricingpoolId) {
            setPricingpoolId(pricingpoolId);
            setPricingPoolLoaded(true);
            getRuleDetails(pricingpoolId);
        } else {
            setPricingpoolId(pricingpoolId);
            setPricingPoolLoaded(false);
        }
    };

    const viewHistory = () => {
        logEvent(EventConstants.BUSINESS_RULE_VIEW_HISTORY, { RuleId: ruleId });
        navigate(getRoute(ROUTES.BUSINESS_RULES_HISTORY, ruleId));
    };

    const onCloseOfRowDetails = () => {
        if (isCreateMode) {
            clearRowDetails();
        }
        setSelectedRuleCreationFlow('');
        setRanchSelected(false);
        setItemSelected(false);
    };

    const clearRowDetails = () => {
        setCreateRowDetails((prev) => ({
            ...prev,
            producingAreaSelectedList: [],
            growerSelectedList: [],
            ranchSelectedList: [],
            berryTypeSelectedList: [],
            fieldTypeSelectedList: [],
            brandSelectedList: [],
            itemSelectedList: []
        }));
    };

    const registerFlowSelction = (flow: string) => {
        setSelectedRuleCreationFlow(flow);
    };

    const shouldLoadFromAssignment = () => {
        return pricingPoolLoaded || ranchSelected || itemSelected;
    };

    const isAddRowEnabled = () => {
        let cardFields = BUSINESSRULE_CARD_FIELDS[Number(ruleTypeId)] || BUSINESSRULE_CARD_FIELDS['default'];
        return cardFields?.packagingPool ? (cardFields?.packagingPool && isValidRuleID(cardFields?.packagingPool?.value)) || pricingPoolLoaded : true;
    };

    const onFlowStepNavigation = (flow: string, direction: string, data: any) => {
        if (flow === 'Item') {
            setRanchSelected(false);
        } else if (flow === 'Ranch') {
            setItemSelected(false);
        }

        if (direction === 'next') {
            setLoadAssignmentOnFilterChange(itemFilterChanged || ranchFilterChanged);
            if (flow === 'Item') {
                onRowFieldChange('producingArea', data);
            } else if (flow === 'Ranch') {
                onRowFieldChange('berryType', data);
            }
        } else if (direction === 'summary') {
            const state = Store.getState();
            const payload = {
                JSONRequest: {
                    Action: 'GET_ASSIGNABLE_LIST',
                    BusinessUnit: userBusinessUnit(),
                    LanguageCode: state?.masterReducer?.userLang?.toLowerCase(),
                    ...(data.ranch &&
                        data.ranch.length > 0 && {
                            RanchFilters: (data.ranch || []).map((item) => {
                                return item.value;
                            })
                        }),
                    ...(data.item &&
                        data.item.length > 0 && {
                            ItemFilters: (data.item || []).map((item) => {
                                return item.value;
                            })
                        })
                }
            };
            getFilteredAssignmentForSummary(payload);
        } else {
            setLoadAssignmentOnFilterChange(false);
        }
    };

    return (
        <DrcWrapper>
            <Stepper
                page={isEditMode ? 'Edit Business Rule' : isCopyMode ? `Create New Business Rule (Copy - ${ruleId})` : 'Create New Business Rule'}
                showBackButton={true}
                goBack={goBack}
            >
                <DriscollsButton
                    buttonSize={ButtonSize.SMALL}
                    variant={ButtonVariant.TEXT}
                    disabled={ruleId ? false : true}
                    onClick={() => viewHistory()}
                >
                    View History
                </DriscollsButton>
            </Stepper>

            <div style={{ padding: '0 20px' }}>
                <CreateBusinessRules
                    getTextTranslated={getTextTranslated}
                    disableAddRowButton={!isAddRowEnabled()}
                    businessRuleCardFields={businessRuleCardFields}
                    createRowDetails={createRowDetails}
                    selectedRowItem={selectedRowItem}
                    onSubmit={onSubmit}
                    isLoading={loading || ranchLoading || growerLoading || producingAreaLoading || itemLoading}
                    showDeleteButton={false}
                    onDeleteRow={onDeleteRow}
                    addRowButtonLabel={
                        isEditMode || isCopyMode
                            ? getTextTranslated('Edit Row', NAMESPACE.BUSINESSRULES)
                            : getTextTranslated('Add New Row', NAMESPACE.BUSINESSRULES)
                    }
                    modalButtonLabel={isEditMode || isCopyMode ? 'Update' : 'Create'}
                    onRowSelectedFieldsChange={onRowSelectedFieldsChange}
                    onRowFieldChange={onRowFieldChange}
                    onCommitChangeOnClose={onCommitChangeOnClose}
                    approverOptions={approverList}
                    onCloseOfRowDetails={onCloseOfRowDetails}
                    onPricingPoolChange={onPricingPoolChange}
                    onResetData={onResetData}
                    registerFlowSelection={registerFlowSelction}
                    onFlowStepNavigation={onFlowStepNavigation}
                    ranchMasterList={assignmentList?.AssignableRanchList}
                    itemMasterList={assignmentList?.AssignableItemList}
                />
                {isEditMode && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'right', marginTop: '5px' }}>
                        <DrcTranslate>{'Results loaded in'}</DrcTranslate> {`${responseTime.toFixed(1)} s`}
                    </div>
                )}
            </div>
            <ExceptionDialog isOpen={openExceptionDialog} onClose={() => setOpenExceptionDialog(false)} response={ruleCreateError} />
        </DrcWrapper>
    );
}

const mapStateToProps = ({ settlementWorkbenchReducer, rootReducer }) => ({
    total: settlementWorkbenchReducer.totalRecords,
    showLoadingScreen: rootReducer.showLoadingScreen,
    loadingScreenMessage: rootReducer.loadingScreenMessage,
    pageTitle: rootReducer.pageTitle,
    records: settlementWorkbenchReducer.records,
    showToast: rootReducer.showToast
});

const mapDispatchToProps = (dispatch) => ({
    showLoadingScreenAction: () => dispatch(showLoadingScreenAction()),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, type) => dispatch(showToast(message, type)),
    setSettlementWorkbenchData: (data) => dispatch(setSettlementWorkbenchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(CreateOrEditBusinessRule)));
