import { format } from 'date-fns';
import APIEndPoints from '../api';
import { urls } from 'constants/urls';

const getParams = (params: object) => {
    return Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
};

const GET_SETTLEMENT_DATA = () => {
    return `${APIEndPoints.BASE}settlementdata`;
};

const GET_SETTLEMENT_AGGREGATE = () => {
    return `${APIEndPoints.BASE}settlementbreakdown`;
};

const GET_SETTLEMENT_DETAILS_HEADER = (poolWeek: string) => {
    return `${APIEndPoints.BASE}settlementagregates?poolweek=${poolWeek}`;
};

const GET_SETTLEMENT_REPORT_DETAILS = () => {
    return `${APIEndPoints.BASE}settlementdetail`;
}

const GET_SETTLEMENT_BREAKDOWN = (params: { poolweek: string; offset: number; limit: number; label: string; datatype: string }) => {
    return `${APIEndPoints.BASE}transactiondata` + '?' + getParams(params);
};

const GET_SETTLEMENT_ENGINE = (poolWeek: string) => {
    return `${APIEndPoints.BASE}settlementengine?poolweek=${poolWeek}`;
};

const GET_SETTLEMENT_ENGINE_STATUS = (poolWeek: string) => {
    return `${APIEndPoints.BASE}settlementengine/status?poolweek=${poolWeek}`
}

const GET_SETTLEMENT_APPROVALS = () => {
    return `${APIEndPoints.BASE}settlementengine/status?status=pending approval`
}

const SEND_SETTLEMENT_MAIL = () => {
    return `${APIEndPoints.BASE}submitsettlement`
}

const POST_SETTLEMENT_RECORD = (poolWeek: string) => {
    //return '`${this.GLUE_JOB}/submit`';
    return `${APIEndPoints.BASE}processsettlement?poolweek=${poolWeek}`;
};

const POST_ASSIGNABLE_LIST = () => {
    return `${APIEndPoints.BASE}${urls.BUSINESS_RULES}/${urls.SUBMIT}/${urls.JSON_DATA}`;
}

export {
    GET_SETTLEMENT_DATA,
    GET_SETTLEMENT_DETAILS_HEADER,
    GET_SETTLEMENT_AGGREGATE,
    GET_SETTLEMENT_BREAKDOWN,
    GET_SETTLEMENT_ENGINE,
    GET_SETTLEMENT_ENGINE_STATUS,
    POST_SETTLEMENT_RECORD,
    POST_ASSIGNABLE_LIST,
    SEND_SETTLEMENT_MAIL,
    GET_SETTLEMENT_APPROVALS,
    GET_SETTLEMENT_REPORT_DETAILS
};
