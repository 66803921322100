import { urls } from 'constants/urls';
import APIEndPoints from '../api';

const GET_PRODUCING_AREA = (pagesize, startpage, isActive, berryType, fieldType) => {
    return `${APIEndPoints.BASE}${urls.PRODUCING_AREA}?isactive=${isActive}&pagesize=${pagesize}&startpage=${startpage}${
        berryType ? `&berrytype=${berryType.toString()}` : ''
    }${fieldType ? `&fieldtype=${fieldType.toString()}` : ''}`;
};

const GET_GROWER = (pagesize, startpage, isActive) => {
    return `${APIEndPoints.BASE}${urls.GROWER}?isactive=${isActive}&pagesize=${pagesize}&startpage=${startpage}`;
};

const GET_RANCH = (pagesize, startpage, isActive) => {
    return `${APIEndPoints.BASE}${urls.RANCH}?isactive=${isActive}&pagesize=${pagesize}&startpage=${startpage}`;
};

const GET_ITEM = (pagesize, startpage, type, isActive) => {
    return `${APIEndPoints.BASE}${urls.ITEM}?isactive=${isActive}&type=${type}&pagesize=${pagesize}&startpage=${startpage}`;
};

export { GET_PRODUCING_AREA, GET_GROWER, GET_RANCH, GET_ITEM };
