import { syncTranslations } from '@driscollsinc/ggs-dashboard-module-ui';
import { ApprovalPage } from '@driscollsinc/ggs-workbench-module-ui';
import { withOktaAuth } from '@okta/okta-react';
import { setPageTitleAction } from 'actions/actions';
import { setSettlementWorkbenchData } from 'actions/settlementWorkbenchAction';
import DrcWrapper from 'components/DrcWrapper';
import Stepper from 'components/Stepper';
import DuDateUtilities from 'drc/driscolls-react-components/Utilities/DuDateUtilities';
import useSettlement from 'hooks/v2/useSettlement';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'routes/withRouter';

function WorkbenchApproval(props) {
    const { i18n } = useTranslation();
    const { getSettlementApprovals, getAggregate, acceptSettlement, rejectSettlement } = useSettlement(props.oktaAuth);

    useEffect(() => {
        syncTranslations(i18n.language);
    }, [i18n.language]);

    return (
        <DrcWrapper>
            <Stepper page={'Workspace / Approval'} />
            <div style={{ padding: '0 20px', height: 'calc(100% - 100px)' }}>
                <ApprovalPage
                    getData={() => getSettlementApprovals()}
                    columns={[
                        {
                            header: 'Poolweek',
                            accessorKey: 'PoolWeek'
                        },
                        {
                            header: 'Submitted By',
                            accessorKey: 'SubmittedBy'
                        },
                        {
                            header: 'Created At',
                            accessorKey: 'CreatedDateTime',
                            cell: ({ cell }) => DuDateUtilities.FormatDateFromIso(cell.getValue(cell.column.id))
                        },
                        {
                            header: 'Modified At',
                            accessorKey: 'ModifiedDateTime',
                            cell: ({ cell }) => DuDateUtilities.FormatDateFromIso(cell.getValue(cell.column.id))
                        },
                        {
                            header: 'Comments',
                            accessorKey: 'Comments'
                        },
                        {
                            header: 'Status',
                            accessorKey: 'Status'
                        }
                    ]}
                    onApproval={async (selectedRows, message) => {
                        await Promise.all(selectedRows.map((row) => acceptSettlement(row.PoolWeek, message)));
                    }}
                    onReject={async (selectedRows, message) => {
                        await Promise.all(selectedRows.map((row) => rejectSettlement(row.PoolWeek, message)));
                    }}
                    getAggregateData={(poolWeek) => getAggregate({ PoolWeek: poolWeek })}
                    onError={(message) => props.showToast(message)}
                />
            </div>
        </DrcWrapper>
    );
}

const mapStateToProps = ({ settlementWorkbenchReducer, rootReducer }) => ({
    total: settlementWorkbenchReducer.totalRecords,
    pageTitle: rootReducer.pageTitle,
    records: settlementWorkbenchReducer.records,
    showToast: rootReducer.showToast
});

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setSettlementWorkbenchData: (data) => dispatch(setSettlementWorkbenchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(WorkbenchApproval)));
