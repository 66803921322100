import { urls } from 'constants/urls';
import APIEndPoints from '../api';

const GET_BUSINESS_RULE_TYPES = () => {
    return `${APIEndPoints.BASE}${urls.RULE_TYPES}`;
};

const GET_ACTIVE_PRICING_POOLS = () => {
    return `${APIEndPoints.BASE}${urls.BUSINESS_RULES}?ruletypeid=1&limit=500&offset=0&rulestatus=${encodeURI('Active')}`;
};

const GET_BUSINESS_RULES = (ruletypeid, pagesize, startpage, filter) => {
    return `${APIEndPoints.BASE}${urls.BUSINESS_RULES}?ruletypeid=${ruletypeid}&limit=${pagesize}&offset=${pagesize * (startpage - 1)}${
        filter ? `&filter=${filter}` : ''
    }`;
};
const GET_BUSINESS_RULES_FOR_APPROVER = (ruletypeid, pagesize, startpage, filter) => {
    return `${APIEndPoints.BASE}${urls.BUSINESS_RULES}?ruletypeid=${ruletypeid}&limit=${pagesize}&offset=${
        pagesize * (startpage - 1)
    }&rulestatus=${encodeURI('PendingApproval')}${filter ? `&filter=${filter}` : ''}`;
};

const GET_BUSINESS_RULES_HISTORY = (ruletypeid, pagesize, startpage, entity, sortorder, sortkey, filter) => {
    return `${APIEndPoints.BASE}${urls.BUSINESS_RULES}/${
        urls.BUSINESS_RULES_HISTORY
    }?ruleid=${ruletypeid}&entity=${entity}&sortorder=${sortorder}&sortkey=${sortkey}&limit=${pagesize}&offset=${pagesize * (startpage - 1)}${
        filter ? `&filter=${filter}` : ''
    }`;
};

const GET_RULES_DETAILS = (ruleid) => {
    return `${APIEndPoints.BASE}${urls.RULE_DETAILS}?ruleid=${ruleid}`;
};

const GET_LOOKUP_DATA = (lookupType: string, businessUnit: string, enabledFlag: string = 'Y', startPage: number = 1, pageSize: number = 100) => {
    return `${APIEndPoints.BASE}${
        urls.LOOKUP_DATA
    }?businessunit=${businessUnit.toLowerCase()}&lookuptype=${lookupType}&enabledflag=${enabledFlag}&startpage=${startPage}&pagesize=${pageSize}`;
};

const POST_SUBMIT_RULE = () => {
    return `${APIEndPoints.BASE}${urls.SUBMIT_RULE}`;
};

const POST_APPROVER_EMAIL = () => {
    return `${APIEndPoints.BASE}${urls.SEND_APPROVER_EMAIL}`;
};

const POST_ASSIGNABLE_LIST = () => {
    return `${APIEndPoints.BASE}${urls.BUSINESS_RULES}/${urls.SUBMIT}/${urls.JSON_DATA}`;
};

export {
    GET_BUSINESS_RULES,
    GET_BUSINESS_RULES_HISTORY,
    GET_BUSINESS_RULE_TYPES,
    GET_LOOKUP_DATA,
    GET_BUSINESS_RULES_FOR_APPROVER,
    GET_RULES_DETAILS,
    POST_APPROVER_EMAIL,
    POST_SUBMIT_RULE,
    POST_ASSIGNABLE_LIST,
    GET_ACTIVE_PRICING_POOLS
};
