import { withOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { withRouter } from 'routes/withRouter';
import { setPageTitleAction, showToast } from 'actions/actions';
import { setSettlementWorkbenchData } from 'actions/settlementWorkbenchAction';
import DrcWrapper from 'components/DrcWrapper';
import { DetailPage, syncTranslations } from '@driscollsinc/ggs-workbench-module-ui';
import useSettlement from 'hooks/v2/useSettlement';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import Stepper from 'components/Stepper';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSettlementMasterData from 'hooks/v2/useSettlementMasterData';
import { Category } from '@mui/icons-material';
import DuExcelUtilities from 'utils/excelUtility';
import { Breadcrumbs, Typography } from '@mui/material';
import { designColors } from 'data/constants';

const getUniqueListBy = (arr: any, key: string) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
};

function WorkbenchDetails(props) {
    const { getAggregate, getFilterDropdownValues } = useSettlement(props.oktaAuth);
    const { state } = useLocation();
    const [params] = useSearchParams();
    const { i18n } = useTranslation();
    const [tabs, setTabs] = useState<string[]>([]);
    const [filters, setFilters] = useState({});
    const [poolWeek, setPoolWeek] = useState(params.get('poolWeek'));
    const [assingableList, setAssignableList] = useState([]);
    const [loadingOptions, setLoadingOptions] = useState(false);

    const pagesize = 1000;
    const startpage = 1;
    const ranchPagesize = 3500;

    useEffect(() => {
        setLoadingOptions(true);
        getFilterDropdownValues(poolWeek, params.get('PFQPool'), params.get('PricingPool'), '', '', '')
            .then((data) => {
                setLoadingOptions(false);
                return setAssignableList(data ?? []);
            })
            .catch(() => setLoadingOptions(false));
    }, []);

    useEffect(() => {
        syncTranslations(i18n.language);
    }, [i18n.language]);

    const exportAsExcel = (rows) => {
        const excelColumns = rows.headers.map((col: any) => ({
            key: col.key,
            name: col.Name
        }));
        DuExcelUtilities.Write('GGS-Settlement_Detail_PW' + poolWeek + '_Data.xlsx', excelColumns, rows);
    };

    return (
        <DrcWrapper>
            <div style={{ padding: '0 20px'}}>
                <DetailPage
                    breadcrumbs={
                        <Breadcrumbs sx={{ color: 'black', '@media (prefers-color-scheme: dark)': { color: `#a7a7a7 !important` } }}>
                            <Typography sx={{ color: designColors.blue, '@media (prefers-color-scheme: dark)': { color: 'white' } }} fontWeight={500}>
                                Home
                            </Typography>
                            <Typography>Workspace</Typography>
                            <Typography>Detail Page</Typography>
                        </Breadcrumbs>
                    }
                    onExport={(data) => exportAsExcel({ data: data?.rows, headers: data?.headers })}
                    defaultWeek={poolWeek}
                    availablePoolWeeks={props.yearPoolWeeks.map((yr) => ({
                        week: yr.PoolWeek,
                        startDate: yr.PoolWeekStartDate,
                        endDate: yr.PoolWeekEndDate
                    }))}
                    getHeaders={async (filters) => {
                        return [];
                    }}
                    renderer={{
                        Category: (data) => (
                            <Link
                                style={{ textDecoration: 'none', color: 'var(--blue-400)' }}
                                to={`../Breakdown?label=${data}&poolWeek=${poolWeek}&PricingPool=${params.get('PricingPool')}&PFQPool=${params.get(
                                    'PFQPool'
                                )}`}
                                state={{ ...state, tabs, filters }}
                            >
                                {data}
                            </Link>
                        )
                    }}
                    getData={async (filters) => {
                        const data = await getAggregate({
                            PoolWeek: filters.poolWeek,
                            PricingPool: filters.PricingPool,
                            PFQPool: filters.PFQPool,
                            ProducingAreaCode: filters.producingArea?.value,
                            RanchNbr: filters.ranch?.value,
                            GrowerNbr: filters.grower?.value
                        });
                        setFilters({
                            ProducingAreaCode: filters.producingArea?.value,
                            RanchNbr: filters.ranch?.value,
                            GrowerNbr: filters.grower?.value
                        });
                        setTabs(data.rows.map((d) => d.Category));
                        setPoolWeek(filters.poolWeek);
                        return data;
                    }}
                    breakdownRoute={{ to: '../Breakdown', options: { state: { tabs, filters, ...state } } }}
                    options={{
                        producingArea: getUniqueListBy(
                            assingableList.map((p) => ({
                                label: `${p.ProducingAreaCode}-${p.ProducingAreaName}`,
                                value: p.ProducingAreaCode
                            })),
                            'value'
                        ),
                        grower: getUniqueListBy(
                            assingableList.map((g) => ({ label: `${g.GrowerNbr}-${g.GrowerName}`, value: g.GrowerNbr })),
                            'value'
                        ),
                        ranch: getUniqueListBy(
                            assingableList.map((r) => ({ label: `${r.RanchNbr}-${r.RanchName}`, value: r.RanchNbr })),
                            'value'
                        )
                    }}
                    optionsLoading={{
                        producingArea: loadingOptions,
                        ranch: loadingOptions,
                        grower: loadingOptions
                    }}
                    onError={(message) => props.showToast(message)}
                    onDetailFilterChange={() => {}}
                />
            </div>
        </DrcWrapper>
    );
}

const mapStateToProps = ({ masterReducer, settlementWorkbenchReducer, rootReducer }) => ({
    total: settlementWorkbenchReducer.totalRecords,
    pageTitle: rootReducer.pageTitle,
    records: settlementWorkbenchReducer.records,
    currentPoolWeek: masterReducer.currentPoolWeek,
    yearPoolWeeks: masterReducer.yearPoolWeeks,
    showToast: rootReducer.showToast
});

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, type) => dispatch(showToast(message, type)),
    setSettlementWorkbenchData: (data) => dispatch(setSettlementWorkbenchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(WorkbenchDetails)));
